import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useFetch, { host } from "../utils/Fetch";

export default function Banner() {
    const [logos, setLogos] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

    const [posX, setPosX] = useState(0);
    const carousel = useRef();
    const [carrouselWidth, setCarrouselWidth] = useState(0);

    const [imagenesLoaded, setImagenesLoaded] = useState(0);

    const { getFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}/sponsors/search/`).then(data => {
            setLogos(data.data);
        });
    }, []);

    useEffect(() => {
        setCarrouselWidth(carousel.current.offsetWidth);

        const interval = setInterval(() => {
            setPosX(prevPosX => {
                if (prevPosX === -carrouselWidth) {
                    // Reset to 0 once it has scrolled past all logos
                    return 0;
                }
                return prevPosX - .5;
            });
        }, 16); // Adjust for smoother movement (60 FPS)

        return () => {
            clearInterval(interval);
        };
    }, [logos.length, imagenesLoaded]);

    return (
        <Box sx={{
            width: '100%',
            height: '70px',
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
        }} >
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    position: 'absolute',
                    left: `${posX}px`,
                    alignItems: 'center',
                }}
                ref={carousel}
            >
                {logos.concat(logos).map((logo, index) => (
                    <img key={index} src={`${host}${logo.logo}`} alt={`logo-${index}`} style={{
                        maxWidth: "80px",
                        height: '70%',
                        objectFit: 'contain',
                        marginLeft: '30px',
                        cursor: "pointer",
                    }}
                        onClick={() => window.location.assign(logo.url)}
                        onLoad={() => setImagenesLoaded(prev => prev + 1)}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    position: 'absolute',
                    left: `${posX + carrouselWidth}px`,
                    alignItems: 'center',
                }}
                ref={carousel}
            >
                {logos.concat(logos).map((logo, index) => (
                    <img key={index} src={`${host}${logo.logo}`} alt={`logo-${index}`} style={{
                        maxWidth: "80px",
                        height: '70%',
                        objectFit: 'contain',
                        marginLeft: '30px',
                        cursor: "pointer",
                    }}
                        onClick={() => window.location.assign(logo.url)}
                        onLoad={() => setImagenesLoaded(prev => prev + 1)}
                    />
                ))}
            </Box>
            {isMobile &&
                <Box sx={{
                    position: 'absolute',
                    width: '20%',
                    height: '100%',
                    background: 'linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1))',
                }} />
            }
        </Box>
    );
}