import { Box, Button, Modal, Typography } from "@mui/material";
import LogoAgenda from "./logo52.png";
import { useState } from "react";

import LogoMuni from "../../assets/partials/logo_muni.svg";
import LogoEntupse from "./logoentupse.png";
import Logo107 from "./107logo.png";
import QR107 from "./107qr.png";
import QRentupse from "./qrentupse.png";

import { isAndroid, isIOS } from 'react-device-detect';

export default function ModalMuni(props) {
    const { sx } = props;


    const herramientas = [
        {
            herramienta: "Servicios",
            nombre: "Agenda 52",
            logo: LogoMuni,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://villamaria.gob.ar/",

        },
        {
            herramienta: "Emergencias",
            nombre: "107",
            // iframe: "http://localhost:3001",
            logo: Logo107,
            descripcion: "Esta aplicación le permitirá utilizar los servicios de emergencias médicas de 107 Villa María. Una vez registrado en la plataforma usted podrá convocar los servicios presionando solamente el botón rojo de emergencia.",
            qr: QR107,
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=avilio.villa.maria&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/107-vm/id6505078770?l=en-GB"
                }
            ]
        },
        {
            herramienta: "Servicios",
            nombre: "Agenda 52",
            logo: LogoAgenda,
            descripcion: "Encuentra profesionales de confianza con facilidad. Accede a perfiles detallados, con ubicación cercana y comunicación directa para una experiencia de contratación sin complicaciones ni retrasos. Fixer, la plataforma que conecta calidad con eficiencia.",
            link: "https://villamaria.agenda52.com/",
            backgroundColor: "#e9722e"
        },
        {
            herramienta: "Estacionamiento",
            nombre: "Entupse",
            qr: QRentupse,
            logo: LogoEntupse,
            descripcion: "Esta herramienta le permitira colaborar con el ordenamiento vial, conocer recorridos y frecuencias del transporte urbano y administrar sus minutos de estacionamiento vehicular por medio de una billetera electrónica.",
            links: [
                {
                    nombre: "Google Play",
                    url: "https://play.google.com/store/apps/details?id=ar.utn.frvm.mvm&hl=es_AR"
                },
                {
                    nombre: "App Store",
                    url: "https://apps.apple.com/ar/app/villa-mar%C3%ADa-ciudad/id1579192962?l=en-GB"
                }
            ]
        },
    ]

    const [modal, setModal] = useState(false);
    const [modalQR, setModalQR] = useState({ open: false, qr: "" });

    return (
        <>
            <img src={LogoMuni} alt="logo" style={{
                width: '100px',
                padding: '20px',
                objectFit: 'contain',
                cursor: "pointer",
                ...sx
            }}
                onClick={() => setModal(true)}
            />
            <Modal
                open={modal && !modalQR.open}
                onClose={() => setModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "400px",

                    maxWidth: "75%",
                    maxHeight: "80%",
                    overflowY: "auto",
                    backgroundColor: "#eee",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 3,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    gap: "30px",
                }}>
                    {herramientas?.map((herramienta, index) => (
                        <Button key={index} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: herramienta.backgroundColor || 'white',
                            borderRadius: 5,
                            boxSizing: "border-box",

                            width: "180px",
                            maxWidth: "calc(50% - 15px)",
                            height: { xs: "150px", sm: "180px" },
                            boxShadow: "0px 5px 5px 0px #aaa",



                            '&:hover': {
                                backgroundColor: herramienta.backgroundColor || 'white',
                                transform: "scale(1.05)",

                            },
                            transition: "all 0.1s ease-in-out",

                            '.MuiTouchRipple-root .MuiTouchRipple-child': {
                                backgroundColor: 'rgba(128, 128, 128, 0.7)' // Color gris translúcido
                            },
                        }}



                            onClick={() => {
                                if (herramienta.links) {
                                    if (isAndroid) {
                                        window.location.assign(herramienta.links[0].url)
                                    } else if (isIOS) {
                                        window.location.assign(herramienta.links[1].url)
                                    } else {
                                        setModalQR({ open: true, qr: herramienta.qr })
                                    }
                                } else {
                                    window.location.assign(herramienta.link)
                                }
                            }}
                        >
                            <img src={herramienta.logo} alt="logo" style={{
                                width: '100px',
                                padding: '20px',
                                objectFit: 'contain',

                            }}
                            />

                        </Button>
                    ))}

                </Box>
            </Modal>
            <Modal
                open={modalQR.open}
                onClose={() => setModalQR({ open: false, qr: "" })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "500px",

                    maxWidth: "75%",
                    maxHeight: "80%",
                    overflowY: "auto",
                    backgroundColor: " white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 3,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    gap: "30px",
                }}>
                    <img src={modalQR.qr} alt="qr" style={{ width: "100%" }} />
                </Box>
            </Modal>
        </>
    )
}