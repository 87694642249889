import { Box, Button, Grid, IconButton, CircularProgress, TextField, Typography, Checkbox, FormControlLabel, Paper, Modal, Autocomplete } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

import DeleteIcon from '@mui/icons-material/Delete';
import ImagePicker from "../../components/ImagePicker";

export default function Signup() {
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [categoriaOtra, setCategoriaOtra] = useState("");
    const [localidadOtra, setLocalidadOtra] = useState("");
    const [instagram, setInstagram] = useState("")
    const [password, setPassword] = useState("");

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);

    const [categorias, setCategorias] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);

    const [terminosAceptados, setTerminosAceptados] = useState(false);

    const [imagePickerModal, setImagePickerModal] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [alerta, setAlerta] = useState({ open: false, mensaje: "", color: "", titulo: "" });

    const [loading, setLoading] = useState(false);

    const [nroMatricula, setNroMatricula] = useState("");

    const { getFetch, postFetchFormData } = useFetch();

    const { setActualPage } = useContext(UserContext);

    useEffect(() => {
        setActualPage("login");
        getFetch(`${host}/api/localidades-profesiones/`).then(data => {
            setCategorias(data.profesiones.map(profesion => ({ ...profesion, seleccionado: false })));
            setUbicaciones(data.localidades.map(localidad => ({ ...localidad, seleccionado: false })));
        });
    }, [])

    function handleImagenChange(data) {
        setImage(data.file);
        setImageURL(data.url);
        setImagePickerModal(false);
    }

    function limpiarDatos() {
        setNombre("");
        setApellido("");
        setTelefono("");
        setEmail("");
        setDni("");
        setDescripcion("");
        setCategoriaOtra("");
        setLocalidadOtra("");
        setInstagram("")
        setPassword("");
        setCategorias(categorias.map(categoria => ({ ...categoria, seleccionado: false })));
        setUbicaciones(ubicaciones.map(ubicacion => ({ ...ubicacion, seleccionado: false })));
        setImage(null);
        setImageURL(null);
    }

    const navigate = useNavigate();

    function registrarse() {
        if (!terminosAceptados) {
            setAlerta({ open: true, mensaje: "Debes aceptar los términos y condiciones", titulo: "Error", icono: <ErrorIcon sx={{ color: "red" }} /> });
            return;
        }

        setLoading(true);
        const ubicacionesSeleccionadas = ubicaciones.filter(ubicacion => ubicacion.seleccionado).map(ubicacion => ubicacion.id);

        const formData = new FormData();
        formData.append("imagen", image);
        formData.append("nombre", nombre);
        formData.append("apellido", apellido);
        formData.append("telefono", telefono);
        formData.append("email", email);
        formData.append("dni", dni);
        formData.append("instagram", instagram)
        formData.append("descripcion", descripcion);
        formData.append("categoriaOtra", categoriaOtra);
        formData.append("localidadOtra", localidadOtra);
        formData.append("categorias", JSON.stringify(categoriasSeleccionadas.map(categoria => ({ id: categoria.id, nroMatricula: categoria.nroMatricula }))));
        formData.append("localidades", JSON.stringify(ubicacionesSeleccionadas));
        formData.append("password", password);

        postFetchFormData(`${host}/api/signup/`, formData)
            .then(data => {
                limpiarDatos();
                setAlerta({ open: true, mensaje: data.message, titulo: "Registro exitoso", icono: <CheckCircleIcon sx={{ color: "green" }} />, onClick: () => { navigate("/login") } });
            })
            .catch(error => {
                console.log(error);
                setAlerta({ open: true, mensaje: error.message, titulo: "Ocurrio un error", icono: <ErrorIcon sx={{ color: "red" }} />, error: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    if (!categorias.length || !ubicaciones.length) {
        return <Grid item container
            xs={12}
            sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}>
            <CircularProgress sx={{
                marginBottom: 20,
                color: "#8DD5FF"
            }}
                size={80} />
        </Grid>
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "700px",
                gap: "40px",
                paddingY: "40px",
                height: "fit-content",
            }}>
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    boxSizing: "border-box",
                    gap: "20px"
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Datos personales</Typography>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            setEmail(e.target.value)
                        }}
                    />
                    <TextField
                        label="Contraseña"
                        value={password}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            )
                        }}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            setPassword(e.target.value)
                        }
                        }

                    />
                    <TextField
                        label="Nombre"
                        value={nombre}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            if (!/^[a-zA-Z\s]*$/.test(e.target.value)) return;
                            setNombre(e.target.value)
                        }}

                    />

                    <TextField
                        label="Apellido"
                        value={apellido}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            if (!/^[a-zA-Z\s]*$/.test(e.target.value)) return;
                            setApellido(e.target.value)
                        }}
                    />
                    <TextField
                        label="DNI"
                        value={dni}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 15) return;
                            if (!/^[0-9]*$/.test(e.target.value)) return;
                            setDni(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este email será al cual se contactarán los clientes via email</Typography>
                    <TextField
                        label="Telefono"
                        value={telefono}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 15) return;
                            if (!/^[0-9]*$/.test(e.target.value)) return;
                            setTelefono(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este número de telefono será al cual se contactarán los clientes via WhatsApp</Typography>
                    <TextField
                        label="Instagram"
                        value={instagram}
                        onChange={(e) => {
                            if (e.target.value.length > 40) return;
                            setInstagram(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este usuario será al cual se contactarán los clientes via Instagram. No ponga el @.</Typography>
                </Paper>
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    boxSizing: "border-box",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Categorías</Typography>
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        gap: "20px",
                        flexWrap: "wrap",
                        alignItems: "flex-end",
                    }}>
                        <Autocomplete
                            id="tags-outlined"
                            options={categorias.filter(categoria => categoriasSeleccionadas.findIndex(cat => cat.id === categoria.id) === -1)}
                            getOptionLabel={(option) => option.nombre}
                            sx={{
                                width: { xs: "100%", sm: "35%" }
                            }}
                            onChange={(e, value) => {
                                setCategoriaSeleccionada(value);
                            }
                            }
                            value={categoriaSeleccionada}

                            renderInput={(params) => (
                                <TextField

                                    {...params}
                                    variant="outlined"
                                    label="Categorías"
                                />
                            )}
                        />
                        <TextField
                            sx={{
                                width: { xs: "100%", sm: "35%" }
                            }}
                            variant="outlined"
                            label="Nro. de matrícula"
                            value={nroMatricula}
                            onChange={(e) => {
                                if (e.target.value.length > 100) return;
                                setNroMatricula(e.target.value)
                            }}
                        />
                        <Button
                            variant="contained"

                            sx={{
                                width: { xs: "100%", sm: "calc(30% - 40px)" },
                                height: "40px",

                                backgroundColor: categoriaSeleccionada ? "#8DD5FF" : "rgba(0, 0, 0, 0.23)",

                                "&:hover": {
                                    backgroundColor: categoriaSeleccionada ? "#8DD5FF" : "rgba(0, 0, 0, 0.23)",
                                }
                            }}
                            disabled={!categoriaSeleccionada}
                            onClick={() => {
                                setCategoriasSeleccionadas([...categoriasSeleccionadas, { ...categoriaSeleccionada, nroMatricula: nroMatricula }]);
                                setCategoriaSeleccionada(null);
                                setNroMatricula("");
                            }}
                        >Agregar</Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "40px",
                            flexWrap: "wrap",
                        }}>
                        {categoriasSeleccionadas.map((categoria, index) => (
                            <Box key={index} sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}>

                                <Typography sx={{
                                    width: { xs: "100%", sm: "40%" }
                                }}>{categoria.nombre}</Typography>
                                <TextField
                                    label="Nro. de matrícula"
                                    value={categoria.nroMatricula}
                                    onChange={(e) => {
                                        setCategoriasSeleccionadas(categoriasSeleccionadas.map((cat, i) => {
                                            if (i === index) {
                                                return { ...cat, nroMatricula: e.target.value }
                                            }
                                            return cat;
                                        }))
                                    }}
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                />

                                <IconButton

                                    onClick={() => {
                                        setCategoriasSeleccionadas(categoriasSeleccionadas.filter((cat, i) => i !== index))
                                    }}
                                >
                                    <DeleteIcon sx={{
                                        color: "red"
                                    }} />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <TextField label="Otra Categoría" value={categoriaOtra}
                        sx={{
                            marginTop: "20px",
                        }}
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            setCategoriaOtra(e.target.value)
                        }} />
                </Paper >
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                    boxSizing: "border-box",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Ubicaciones</Typography>
                    <Grid container item sx={{
                        overflowY: "auto",
                    }}>
                        {ubicaciones.map((ubicacion, index) => (
                            <Grid item xs={6} key={index}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={ubicacion.nombre}
                                    sx={{
                                        wordBreak: "break-word",
                                    }}
                                    checked={ubicacion.seleccionado}
                                    onChange={(e) => {
                                        setUbicaciones(ubicaciones.map((ubic, i) => {
                                            if (i === index) {
                                                return { ...ubic, seleccionado: e.target.checked }
                                            }
                                            return ubic;
                                        })
                                        )
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <TextField label="Otra" value={localidadOtra} onChange={(e) => {
                        if (e.target.value.length > 100) return;
                        setLocalidadOtra(e.target.value)
                    }} />
                </Paper >
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                    boxSizing: "border-box",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Perfil</Typography>
                    <Box sx={{
                        height: "100%",
                        aspectRatio: "1/1",
                        cursor: "pointer",
                        position: "relative",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                    }}

                        onClick={() => setImagePickerModal(true)}
                    >
                        {image ? <img src={imageURL} alt="Imagen cargada" style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }} /> : <Typography>Subir foto</Typography>}
                        {image &&
                            <Box
                                sx={{
                                    height: "50px",
                                    width: "50px",
                                    backdropFilter: "blur(10px)",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "40px",
                                    borderRadius: "0 0 0 30px",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setImage(null);
                                    setImageURL(null);
                                }}
                            >
                                <IconButton>
                                    <CloseIcon sx={{
                                        color: "white",

                                    }} />
                                </IconButton>
                            </Box>
                        }
                    </Box>
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Esta imagen será la que se muestre en tu perfil</Typography>
                    <TextField
                        label="Descripción"
                        value={descripcion}
                        required
                        onChange={(e) => setDescripcion(e.target.value)}
                        multiline
                        rows={4}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)",
                    }}>Esta descripción será la que se muestre en tu perfil</Typography>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <Checkbox
                            checked={terminosAceptados}
                            onChange={(e) => setTerminosAceptados(e.target.checked)}
                            sx={{
                                color: "#8DD5FF",
                                margin: 0,
                                '&.Mui-checked': {
                                    color: "#8DD5FF"
                                }
                            }} />
                        <Typography sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                        }}
                        >Acepto los <Typography sx={{
                            color: "#8DD5FF",
                            display: "inline",
                            fontWeight: "bold",
                            cursor: "pointer",
                        }} onClick={() => window.open("/terminos-y-condiciones", "_blank")
                        }>términos y condiciones</Typography></Typography>
                    </Box>
                </Paper >
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    padding: "20px",
                    boxSizing: "border-box",
                }}>
                    < Button
                        sx={{
                            width: "400px",
                            maxWidth: "100%",
                            height: "50px",
                            backgroundColor: "#8DD5FF",

                            "&:hover": {
                                backgroundColor: "#8DD5FF",
                            }
                        }}

                        variant="contained" color="primary"
                        onClick={loading ? null : registrarse}
                    >Registrarse</Button>

                    <Typography
                        onClick={() => navigate("/login")}
                        sx={{
                            cursor: "pointer",
                        }}>
                        Tienes una cuenta? <Typography sx={{
                            color: "#8DD5FF",
                            cursor: "pointer",
                            display: "inline",
                            fontWeight: "bold",
                        }}>
                            Inicia sesión
                        </Typography>
                    </Typography>
                </Paper>
            </Box>
            <Modal
                open={alerta.open}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                disableAutoFocus
                onClose={() => setAlerta({ open: false, mensaje: "", color: "" })}
            >
                <Paper sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "20px",
                    width: "80%",
                    maxWidth: "400px",
                }}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                    }}>
                        {alerta.icono}
                        <Typography variant="h5" fontWeight={"bold"}>{alerta.titulo}</Typography>
                    </Box>
                    <Typography variant="h6" sx={{
                    }}>{alerta.mensaje}</Typography>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button
                            sx={{
                                width: "100px",
                                backgroundColor: "#8DD5FF",

                                "&:hover": {
                                    backgroundColor: "#8DD5FF",

                                }
                            }}
                            variant="contained" color="primary"
                            onClick={() => {
                                setAlerta({ open: false, mensaje: "", color: "" })
                                if (alerta.onClick) alerta.onClick();
                            }}
                        >Aceptar</Button>
                    </Box>
                </Paper>
            </Modal>
            <Modal
                open={imagePickerModal}
                onClose={() => setImagePickerModal(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ImagePicker
                    borderRadius={"15px"}
                    size={370}
                    onSubmit={handleImagenChange} />
            </Modal>
        </Box >
    )
}