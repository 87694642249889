import React, { useContext, useEffect } from 'react';
import { Box, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useState } from 'react';
import useFetch, { host } from '../../utils/Fetch';
import { default as PerfilComponent } from '../../components/Perfil';
import { UserContext } from '../../contexts/UserContext';

import SettingsIcon from '@mui/icons-material/Settings';
import ImagePicker from '../../components/ImagePicker';

export default function Perfil() {
    const [perfil, setPerfil] = useState(null);

    const { getFetch, putFetchFormData } = useFetch();

    const [settingsModal, setSettingsModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [changeImageModal, setChangeImageModal] = useState(false);

    const { setActualPage, logout } = useContext(UserContext)

    useEffect(() => {
        setActualPage("perfil");
    }, [])

    useEffect(() => {
        getFetch(`${host}/perfil/`, true)
            .then(data => {
                setPerfil(data);
            }).catch(error => {
                console.log(error);
            })

        // getFetch(`${host}/publicaciones/`, true)
        //     .then(data => {
        //         setPublicaciones(data);
        //     }).catch(error => {
        //         console.log(error);
        //     })
    }, []);

    function handleSubmitImage(data) {
        console.log(data);

        const formData = new FormData();
        formData.append("id", perfil.id);
        formData.append("foto_perfil", data.file);

        putFetchFormData(`${host}/expertos/`, formData)
            .then(response => {

                setPerfil(response.data);
                setChangeImageModal(false);
            }).catch(error => {
                console.log(error);
            })
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
        }}>
            <PerfilComponent
                perfil={perfil}
                setPerfil={setPerfil}
                isOwn={true}
            />
            <IconButton sx={{
                position: "absolute",
                top: 15,
                right: 20,
            }}
                onClick={() => setSettingsModal(true)}
            >
                <SettingsIcon />
            </IconButton>
            <Modal
                open={settingsModal}
                onClose={() => setSettingsModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "300px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Configuración
                    </Typography>
                    <Box sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        marginY: "10px",
                    }} />
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setModalEdit(true);
                            setSettingsModal(false);
                        }}>
                        <Typography>
                            Editar
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            logout()
                            setSettingsModal(false);
                        }}>
                        <Typography>
                            Cerrar Sesión
                        </Typography>
                    </Box>

                </Box>
            </Modal>
            <Modal
                open={modalEdit && !changeImageModal}
                onClose={() => setModalEdit(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "300px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Editar
                    </Typography>
                    <Box sx={{
                        position: "relative",
                    }}>
                        <Box sx={{
                            width: "200px",
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            position: "absolute",
                            borderRadius: "50%",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            alignItems: "center",
                        }}
                            onClick={() => setChangeImageModal(true)}
                        >
                            <Typography sx={{
                                color: "#fff",
                            }}>
                                Cambiar foto
                            </Typography>
                        </Box>
                        <img src={host + "/" + perfil?.foto_perfil}
                            alt="foto_perfil"
                            style={{
                                width: "200px",
                                height: "200px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "10px",
                            }}
                        />
                    </Box>
                    {/* <Box sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        marginY: "10px",
                    }} />
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Apellido"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Correo"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Contraseña"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            // handleEliminarPublicacion(publicacion);
                            // setMoreModal(false);
                        }}>
                        <Typography>
                            Guardar
                        </Typography>
                    </Box> */}
                </Box>
            </Modal>
            <Modal
                open={changeImageModal}
                onClose={() => setChangeImageModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <ImagePicker
                        size={370}
                        borderRadius={"15px"}
                        onSubmit={handleSubmitImage}
                    />
                </Box>
            </Modal>
        </Box>
    )
}